import Footer from "@src/components/common/footer";
import Layout from "@src/components/common/Layout";
import Loading from "@src/components/common/Loading";
import ReportNotAvailable from "@src/components/powerBiEmbed/ReportNotAvailable";
import { useGetUserReportsQuery, useLazyGetReportQuery } from "@src/services/reports";
import { IReports } from "@src/shared/interfaces/reports.interface";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { use, useEffect, useState } from "react";

import { NextPageWithLayout } from "../_app";

const PowerBiEmbed = dynamic(() => import("@src/components/powerBiEmbed"), { ssr: false });

interface ReportProps {
  reports: IReports[];
}

const Report: NextPageWithLayout<ReportProps> = ({ reports }) => {
  const router = useRouter();
  const { slugs } = router.query;
  const [workspaceId, externalId] = Array.isArray(slugs) ? slugs : [];

  const [trigger, { isLoading, data, isFetching, isError }] = useLazyGetReportQuery();
  const { data: userReportsData } = useGetUserReportsQuery({});
  const { token, reportFilters, reportPages } = data || {};

  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!workspaceId && !externalId) return;
    trigger({ workspaceId, externalId });
  }, [workspaceId, externalId, trigger]);

  useEffect(() => {
    if (!userReportsData || !userReportsData.reports || !Array.isArray(userReportsData.reports)) {
      return;
    }

    const userReports = userReportsData.reports;
    const access = userReports.some((report: IReports) => report.externalId === externalId);

    if (access !== hasAccess) setHasAccess(access);
  }, [userReportsData, externalId, hasAccess]);

  if (isError || !hasAccess) return <ReportNotAvailable />;
  if (isLoading || isFetching)
    return (
      <>
        <div className="w-full relative flex justify-center items-center mt-20">
          <Loading color="fill-neutral-900" />
        </div>
      </>
    );
  return (
    <>
      {token && (
        <PowerBiEmbed
          externalId={externalId}
          token={token}
          filters={reportFilters}
          activePages={reportPages}
        />
      )}
      <div className="ml-5">
        <Footer />
      </div>
    </>
  );
};

Report.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

export default Report;
