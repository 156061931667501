import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";

import Loading from "../Loading";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  size?: "medium" | "small" | "large" | "x-small" | "md-afya" | "default";
  variant?:
    | "primary"
    | "secondary"
    | "afya"
    | "default"
    | "gray"
    | "afyaSecondary"
    | "whiteBlue"
    | "danger"
    | "neutral"
    | "tertiary";
  full?: boolean;
}

export default function Button(props: Props) {
  const { children, isLoading, disabled, className, size, variant, full, ...rest } = props;

  const baseClass = classNames("font-semibold leading-6 flex justify-center items-center", {
    "w-full": full,
  });

  const variants = {
    primary:
      "bg-neutral-900 text-neutral-0 disabled:bg-neutral-200 hover:bg-afya-3 hover:text-black disabled:text-neutral-400",
    secondary:
      "bg-neutral-0 text-neutral-900 border border-neutral-900 hover:border-neutral-400 hover:text-black disabled:border-neutral-300 disabled:text-neutral-300",
    afya: "bg-afya-0 text-neutral-0 disabled:bg-neutral-200 hover:bg-afya-2 disabled:text-neutral-400",
    afyaSecondary:
      "bg-afya-4 hover:bg-blue-700 text-neutral-0 disabled:bg-neutral-200 disabled:text-neutral-400 font-normal",
    default:
      "bg-afya-4 hover:bg-afya-5 text-neutral-0 disabled:bg-neutral-200 disabled:text-neutral-400 font-normal",
    gray: "bg-gray-300 hover:bg-gray-100 text-neutral-900  border-neutral-900  hover:text-neutral-400 disabled:border-neutral-300 disabled:text-neutral-300",
    whiteBlue: "bg-transparent font-medium text-[#003E8A] hover:bg-afya-6",
    neutral: "bg-white text-neutral-900 border border-neutral-300 hover:bg-neutral-100",
    danger: "bg-[#DA3333] text-[#FFFFFF] hover:bg-[#C92A2A] disabled:bg-red-400",
    tertiary:
      "bg-red-0 text-red-400 border border-red-400 hover:border-red-900 hover:text-red-900 disabled:border-red-300 disabled:text-red-300",
  };

  const variantSizes = {
    large: "py-2 px-4 h-[56px] text-base rounded-lg",
    medium: "py-2 px-4 h-[44px] text-base rounded-lg",
    small: "py-2 px-4 h-[40px] text-sm rounded-md",
    "x-small": "py-2 px-3 h-[36px] text-sm rounded-md",
    ["md-afya"]: "py-2 px-4 h-[40px] w-[99px] text-base rounded-lg",
    default: "py-2 px-3 h-[40px] w-[169px] text-base rounded-md",
  };

  const loadingVariantColor = variant === "primary" ? "fill-neutral-0" : "fill-neutral-900";

  const btnClass = classNames(
    baseClass,
    variantSizes[size],
    variants[variant],
    variantSizes,
    className,
  );
  return (
    <button {...rest} disabled={disabled} className={`${btnClass}`}>
      {isLoading ? <Loading color={loadingVariantColor} /> : children}
    </button>
  );
}
