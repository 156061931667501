import Footer from "@src/components/common/footer";
import Image from "next/image";

const GenericReportChart = (
  <Image
    src={"/genericReportChart.svg"}
    alt="arrow icon"
    className="ml-4"
    width={295}
    height={239}
    priority
  />
);

export default function ReportNotAvailable() {
  return (
    <div className="h-screen w-full bg-body relative pt-5">
      <div className="mx-4 h-[85vh] bg-white border border-afyagray-200 rounded items-center flex-row justify-center shadow-sm content-center grid">
        <div className="w-full col-start-1 row-start-1">{GenericReportChart}</div>
        <div className="text-lg leading-6 font-normal font-nunito px-6 text-afyagray-400 text-center col-start-1 row-start-2">
          O relatório não está disponível.
        </div>
      </div>
      <div className="bottom-0 mx-4 absolute">
        <Footer />
      </div>
    </div>
  );
}
