import Close from "@src/assets/icons/Close";
import Modal from "@src/components/modal";

import style from "./requestToHolder.module.scss";

export default function RequestToHolder({ onClose }: any) {
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <div>
      <Modal>
        <div className="bg-neutral-0 bg-opacity-25 bg-black rounded-xl">
          <div className="h-full pb-6">
            <div className="flex items-center font-nunito justify-between p-4 md:p-5 dark:border-gray-600">
              <h3 className="text-2xl font-nunito font-bold text-gray-900">Pedido do Titular</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                data-modal-hide="default-modal"
                onClick={handleCloseModal}
              >
                <Close />
              </button>
            </div>
            <iframe
              className={style.frame}
              src={process.env.NEXT_PUBLIC_PRIVACY_URL_REQUEST_HOLDER}
            ></iframe>
          </div>
        </div>
      </Modal>
    </div>
  );
}
