import { AppDispatch, RootState } from "@src/store";
import { loginActions } from "@src/store/slices/login";
import { User } from "@src/store/slices/login/types";
import jwtDecode from "jwt-decode";

import { api } from "./api";

interface ILoginData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const login =
  (body: ILoginData) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(loginActions.controlView({ isLoading: true, hasError: false }));

      const { data, status } = await api.post("/auth/sign-in", body);

      if (status !== 200) {
        throw new Error("Erro ao realizar login");
      }

      const user = jwtDecode<User>(data.accessToken);

      dispatch(
        loginActions.controlData({
          token: user.privacy.assign ? data.accessToken : null,
          user: {
            name: user.name,
            email: user.email,
            roles: user.roles,
            privacy: user.privacy,
          },
        }),
      );
      dispatch(loginActions.controlView({ isLoading: false, hasError: false }));

      return {
        privacy: user.privacy,
        token: user,
      };
    } catch (err: any) {
      dispatch(loginActions.controlView({ isLoading: false, hasError: true }));

      throw err;
    }
  };

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(
      loginActions.controlData({
        token: null,
        user: null,
      }),
    );
    dispatch(loginActions.controlView({ expireError: false }));
  } catch (err: any) {
    throw err;
  }
};

export const forgotPassword =
  (email: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(loginActions.controlView({ isLoading: true, hasError: false }));

      const { status } = await api.post("/auth/send-define-password", {
        email,
        type: "RESET_PASSWORD",
      });

      if (status !== 204) throw new Error("Erro ao enviar email de redefinição de senha");
      dispatch(loginActions.controlView({ isLoading: false, hasError: false }));
    } catch (err) {
      console.error(err);
      dispatch(loginActions.controlView({ isLoading: false, hasError: false }));
      throw err;
    }
  };

export const sendPasswordResetEmail =
  (email: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(loginActions.controlView({ isLoading: true, hasError: false }));

      const { data, status } = await api.post("/auth/send-define-password", {
        email,
        type: "RESET_PASSWORD",
      });

      if (status !== 204) throw new Error("Erro ao enviar email de redefinição de senha");
      dispatch(loginActions.controlView({ isLoading: false, hasError: false }));
    } catch (err) {
      console.error(err);
      dispatch(loginActions.controlView({ isLoading: false, hasError: false }));
      throw err;
    }
  };

export const sendWelcomeEmail =
  (email: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(loginActions.controlView({ isLoading: true, hasError: false }));

      const { data, status } = await api.post("/auth/send-define-password", {
        email,
        type: "WELCOME",
      });

      if (status !== 204) throw new Error("Erro ao enviar email inicial");
      dispatch(loginActions.controlView({ isLoading: false, hasError: false }));
    } catch (err) {
      console.error(err);
      dispatch(loginActions.controlView({ isLoading: false, hasError: false }));
      throw err;
    }
  };
