import RequestToHolder from "@src/components/requestToHolder";
import Link from "next/link";
import { useState } from "react";

export default function Footer() {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleOpenReportModal = () => {
    setIsReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setIsReportModalOpen(false);
  };
  return (
    <div>
      <footer className="lg:text-left mb-5 mt-12 position-relative bg-transparent">
        <ul className="flex flex-wrap items-center ml-5 mt-3 font-nunito text-xs font-medium dark:text-gray-400 sm:mt-0">
          <li>
            {" "}
            <a
              className="me-4 md:me-6 text-[#0B63CB] cursor-pointer"
              onClick={handleOpenReportModal}
            >
              Pedido do Titular
            </a>{" "}
            {isReportModalOpen && <RequestToHolder onClose={handleCloseReportModal} />}
          </li>
          <li>
            {" "}
            <Link className="me-4 md:me-6 text-[#0B63CB]" href="/privacy-policy">
              Política de Privacidade
            </Link>{" "}
          </li>
          <li>
            {" "}
            <Link className="me-4 md:me-6 text-[#0B63CB]" href="/cookie-policy">
              Política de Cookies
            </Link>{" "}
          </li>
          <li>
            {" "}
            <Link className="me-4 md:me-6 text-[#0B63CB]" href="/use-terms">
              Termos de Uso
            </Link>{" "}
          </li>
        </ul>
      </footer>
    </div>
  );
}
