interface Props {
  color?: "fill-neutral-0" | "fill-neutral-900" | "fill-neutral-400";
}

export default function Loading(props: Props) {
  const color = props.color ? props.color : "fill-neutral-0";
  return (
    <svg
      className={`animate-spin ${color}`}
      width="36"
      height="34"
      viewBox="0 0 36 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.5 19.04C29.6598 19.04 30.6 18.1266 30.6 17C30.6 15.8733 29.6598 14.96 28.5 14.96C27.3402 14.96 26.4 15.8733 26.4 17C26.4 18.1266 27.3402 19.04 28.5 19.04Z" />
      <path
        d="M23.9397 25.6549C24.7598 26.4516 26.0894 26.4516 26.9095 25.6549C27.7296 24.8582 27.7296 23.5666 26.9095 22.7699C26.0894 21.9732 24.7598 21.9732 23.9397 22.7699C23.1196 23.5666 23.1196 24.8582 23.9397 25.6549Z"
        fillOpacity="0.875"
      />
      <path
        d="M15.9 27.1999C15.9 28.3266 16.8402 29.2399 18 29.2399C19.1598 29.2399 20.1 28.3266 20.1 27.1999C20.1 26.0733 19.1598 25.1599 18 25.1599C16.8402 25.1599 15.9 26.0733 15.9 27.1999Z"
        fillOpacity="0.75"
      />
      <path
        d="M9.09045 22.7699C8.27035 23.5666 8.27035 24.8582 9.09045 25.6549C9.91056 26.4516 11.2402 26.4516 12.0603 25.6549C12.8804 24.8582 12.8804 23.5666 12.0603 22.7699C11.2402 21.9732 9.91056 21.9732 9.09045 22.7699Z"
        fillOpacity="0.625"
      />
      <path
        d="M7.50001 14.96C6.34021 14.96 5.40001 15.8734 5.40001 17C5.40001 18.1267 6.34021 19.04 7.50001 19.04C8.6598 19.04 9.60001 18.1267 9.60001 17C9.60001 15.8734 8.6598 14.96 7.50001 14.96Z"
        fillOpacity="0.5"
      />
      <path
        d="M12.0603 8.34511C11.2402 7.54844 9.91056 7.54844 9.09045 8.34511C8.27035 9.14178 8.27035 10.4334 9.09045 11.2301C9.91056 12.0268 11.2402 12.0268 12.0603 11.2301C12.8804 10.4334 12.8804 9.14178 12.0603 8.34511Z"
        fillOpacity="0.375"
      />
      <path
        d="M20.1 6.80009C20.1 5.67343 19.1598 4.76009 18 4.76009C16.8402 4.76009 15.9 5.67343 15.9 6.80009C15.9 7.92675 16.8402 8.84009 18 8.84009C19.1598 8.84009 20.1 7.92675 20.1 6.80009Z"
        fillOpacity="0.25"
      />
      <path
        d="M26.9095 11.2301C27.7296 10.4334 27.7296 9.14177 26.9095 8.3451C26.0894 7.54843 24.7598 7.54843 23.9397 8.3451C23.1196 9.14177 23.1196 10.4334 23.9397 11.2301C24.7598 12.0268 26.0894 12.0268 26.9095 11.2301Z"
        fillOpacity="0.125"
      />
    </svg>
  );
}
